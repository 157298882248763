exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-home-section-feature-js": () => import("./../../../src/pages/home-section/feature.js" /* webpackChunkName: "component---src-pages-home-section-feature-js" */),
  "component---src-pages-home-section-footer-js": () => import("./../../../src/pages/home-section/footer.js" /* webpackChunkName: "component---src-pages-home-section-footer-js" */),
  "component---src-pages-home-section-navigation-js": () => import("./../../../src/pages/home-section/navigation.js" /* webpackChunkName: "component---src-pages-home-section-navigation-js" */),
  "component---src-pages-home-section-package-js": () => import("./../../../src/pages/home-section/package.js" /* webpackChunkName: "component---src-pages-home-section-package-js" */),
  "component---src-pages-home-section-pemilu-js": () => import("./../../../src/pages/home-section/pemilu.js" /* webpackChunkName: "component---src-pages-home-section-pemilu-js" */),
  "component---src-pages-home-section-product-js": () => import("./../../../src/pages/home-section/product.js" /* webpackChunkName: "component---src-pages-home-section-product-js" */),
  "component---src-pages-home-section-promotion-js": () => import("./../../../src/pages/home-section/promotion.js" /* webpackChunkName: "component---src-pages-home-section-promotion-js" */),
  "component---src-pages-home-section-video-js": () => import("./../../../src/pages/home-section/video.js" /* webpackChunkName: "component---src-pages-home-section-video-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-term-and-condition-js": () => import("./../../../src/pages/term-and-condition.js" /* webpackChunkName: "component---src-pages-term-and-condition-js" */)
}

